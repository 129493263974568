<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    persistent
    transition="dialog-bottom-transition"
    v-if="isDataLoadedFromServer"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins h-100 overflow-hidden">
      <!--begin::Modal header-->
      <div class="modal-header align-center">
        <h3 class="mb-0 font-weight-bolder">Edit Template</h3>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body mx-5 mx-xl-15 py-4 my-0 py-0">
        <!--begin::Body-->
        <form
          id="createForm"
          class="row overflow-y-auto scrollable h-100 pt-2 pb-12"
          style="overflow-y: scroll; max-height: 96vh"
        >
          <div
            class="col-12 col-sm-6 py-0 my-0"
            v-if="serverData?.organizations?.length > 0"
          >
            <v-select
              v-model="formData.organization_id"
              label="Organizaiton"
              item-text="text"
              item-value="index"
              :items="serverData.organizations"
              clearable
              outlined
              dense
              required
              :error-messages="organization_idErrors"
              @input="$v.formData.organization_id.$touch()"
              @blur="$v.formData.organization_id.$touch()"
            ></v-select>
          </div>
          <div
            class="col-12 col-sm-6 py-0 my-0"
            v-if="serverData?.customers?.length > 0"
          >
            <v-select
              v-model="formData.customer_id"
              label="Customer"
              item-text="text"
              item-value="index"
              :items="serverData.customers"
              clearable
              outlined
              dense
              required
              :error-messages="customer_idErrors"
              @input="$v.formData.customer_id.$touch()"
              @blur="$v.formData.customer_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-select
              v-model="formData.module_id"
              label="Module"
              item-text="text"
              item-value="index"
              :items="serverData.modules"
              clearable
              outlined
              dense
              required
              :error-messages="module_idErrors"
              @input="$v.formData.module_id.$touch()"
              @blur="$v.formData.module_id.$touch()"
            ></v-select>
          </div>
          <div class="col-12 col-sm-6 py-0 my-0">
            <v-text-field
              v-model="formData.title"
              label="Title"
              dense
              outlined
              clearable
            ></v-text-field>
          </div>
          <div class="col-12 my-0 py-0 row">
            <div class="ps-3 my-0 py-0">
              <button
                class="btn btn--export-filter"
                @click.prevent="showInsertTableDialog"
                :disabled="!formData.module_id"
              >
                Configure table
              </button>
            </div>
            <div class="ps-3 my-0 py-0">
              <button
                class="btn btn--export-filter"
                @click.prevent="showConfigureFilterDialog"
                :disabled="!formData.module_id"
              >
                Configure filter
              </button>
            </div>
          </div>
          <div class="col-12 row pt-4 pb-0 px-5">
            <div
              class="px-1 py-1"
              v-for="variable in formData.body_formulas"
              :key="variable.text"
            >
              <v-chip
                link
                outlined
                @click="insertSelectedText(`%${variable.text}%`)"
                >{{ variable.text }}</v-chip
              >
            </div>
          </div>
          <div class="col-12 row pb-4 pt-0 px-5">
            <div
              class="px-1 py-1"
              v-for="variable in variablesOfModule"
              :key="variable.value"
            >
              <v-chip
                link
                outlined
                @click="insertSelectedText(variable.value)"
                >{{ variable.text }}</v-chip
              >
            </div>
          </div>
          <div class="col-12">
            <div class="row justify-center">
              <button
                type="reset"
                class="btn btn-light mr-3 px-5 py-3 ls1"
                @click="resetCreateForm"
              >
                Clear
              </button>
              <button
                type="reset"
                class="btn btn-secondary mr-3 px-5 py-3 ls1"
                @click="saveAsCopy"
              >
                Save As Copy
              </button>
              <button
                type="submit"
                class="btn btn-info px-5 py-3 ls1"
                @click.prevent="submitCreateForm"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->

        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <insert-table
      ref="insertColumn"
      :available-columns="columnsLeftAfterSelection"
      :selected-columns="formData.table_columns"
      :insert-table-to-template="setTableColumnsWithAddingOrder"
      :available-formulas="serverData.formulas"
    />
    <ConfigureFilter
      ref="configureFilter"
      :available-columns="filtersLeftAfterSelection"
      :selected-columns="formData.filters"
      :setColumns="setFilters"
    />
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import validationMixin from "@/own/mixins/validationMixin";
import { required, requiredIf } from "vuelidate/lib/validators";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import InsertTable from "./InsertTable.vue";
import ConfigureFilter from "./ConfigureFilter.vue";

export default {
  mixins: [validationMixin],
  validations: {
    formData: {
      organization_id: {
        required: requiredIf(function () {
          return this.serverData?.organizations?.length > 0;
        }),
      },
      customer_id: {
        required: requiredIf(function () {
          return this.serverData?.customers?.length > 0;
        }),
      },
      module_id: { required },
    },
  },
  components: { InsertTable, ConfigureFilter },
  name: "EditItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    formData: {
      id: null,
      organization_id: null,
      customer_id: null,
      module_id: null,
      title: null,
      table_columns: [],
      filters: [],
    },
    serverData: null,
  }),
  computed: {
    isDataLoadedFromServer() {
      return !!this.serverData;
    },
    variablesOfModule() {
      if (!this.formData.module_id) {
        return [];
      }
      return this.serverData.dynamic_values.find(
        (mod) => mod.module_id == this.formData.module_id
      )?.fields;
    },
    tableColumnsOfModule() {
      if (!this.formData.module_id) {
        return [];
      }
      return this.serverData.dynamic_values.find(
        (mod) => mod.module_id == this.formData.module_id
      )?.table_keys;
    },
    organization_idErrors: function () {
      return this.handleFormValidation("organization_id", this);
    },
    customer_idErrors: function () {
      return this.handleFormValidation("customer_id", this);
    },
    module_idErrors: function () {
      return this.handleFormValidation("module_id", this);
    },
    columnsLeftAfterSelection() {
      return this.tableColumnsOfModule.filter(
        (item1) =>
          !this.formData.table_columns.some(
            (item2) => item2.value === item1.value
          )
      );
    },
    filtersOfModule() {
      if (!this.formData.module_id) {
        return [];
      }
      return this.serverData.dynamic_values.find(
        (mod) => mod.module_id == this.formData.module_id
      )?.filter_keys;
    },
    filtersLeftAfterSelection() {
      return this.filtersOfModule.filter(
        (item1) =>
          !this.formData.filters.some((item2) => item2.name === item1.name)
      );
    },
  },
  methods: {
    showConfigureFilterDialog() {
      this.$refs.configureFilter.toggleModal();
    },
    setFilters(data) {
      this.formData.filters = data;
    },
    saveAsCopy() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      const data = this.formData;
      ApiService.post("/settings/dynamic_reports/store", data)
        .then(() => {
          Swal.fire({
            title: "Created",
            text: "The record has been created",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    showInsertTableDialog() {
      this.$refs.insertColumn.toggleModal();
    },
    setTableColumnsWithAddingOrder(data) {
      data = data.map((a, i) => {
        a["order"] = i;
        return a;
      });
      this.formData.table_columns = data;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let data = { id: this.$store.getters.getItemForAction.id };
      ApiService.post("/settings/dynamic_reports/edit", data)
        .then((response) => {
          this.serverData = response.data;
          this.formData = this.serverData.document;
          this.pageLoader(false);
        })
        .catch(() => {
          this.dialog = false;
          this.loader(false);
        });
    },
    clearEventsLog: function () {
      this.events = [];
    },
    toggleModal() {
      if (!this.dialog) {
        this.loadDataFromServer();
      } else {
        this.serverData = null;
        this.resetCreateForm();
      }
      this.dialog = !this.dialog;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.pageLoader(true);
      const data = this.formData;
      ApiService.post("/settings/dynamic_reports/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: "The record has been updated",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.resetCreateForm();
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    resetCreateForm() {
      this.formData = {
        id: null,
        organization_id: null,
        customer_id: null,
        module_id: null,
        title: null,
        table_columns: [],
        filters: [],
      };
      this.$nextTick(() => {
        this.$v.$reset();
      });
    },
  },
};
</script>
<style></style>
